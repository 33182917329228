import "./App.css";
import axios from "axios";
import beep from "./assets/beep.wav";
// import dar from "./assets/darmanlo.aac"
import { useEffect, useState } from "react";
function App() {
  const [tickets, setTickets] = useState([]);
  const [counter, setCounter] = useState(0);
  const [status, setStatus] = useState("nothing");
  const [error, setError] = useState("");
  const [buttonState, setButtonState] = useState(false);
  useEffect(() => {
    if (counter === 0) {
      getTickets();
      setCounter(counter + 1);
    }
    const timeOutId = setTimeout(() => {
      // const audio = new Audio(""); //https://www.soundjay.com/buttons/beep-01a.wav
      // audio.play();
      getTickets();
      setCounter(counter + 1);
    }, 20000);
    return () => {
      clearTimeout(timeOutId);
    };
  }, [counter]);
  const renderTickets = () => {
    const availableTickets = tickets.filter((ticket) => ticket.Counting > 0);
    return availableTickets.map((ticket, index) => {
      return (
        <div key={index} className="ticket">
          <div>{ticket.ExitTime} </div>
          <div>{ticket.TimeOfArrival}</div>
          <div>{ticket.Counting}</div>
          <div>{ticket.FullPrice}</div>
        </div>
      );
    });
  };
  const getTickets = async () => {
    try {
      const { data } = await axios.get(
        "https://hostservice.raja.ir/Api/ServiceProvider/TrainListEq?q=tORAU2SYJeIv6+m2p6keQVFZbHLJKGGa0ubRHjjZk1rsjcg/9UGPk2kKRBiuNZrtAM2Mv5jveJd/TwHgK+yPWYymF7voWX+wK8YcAYzxUeM="
      );
      const { data: data2 } = await axios.get(
        "https://hostservice.raja.ir/Api/ServiceProvider/TrainListEq?q=WPnre1J30slHh4fZbKHCcpTJlYFjhWEYp4UlhKOnK8xOZKJE02jl9+pTibk6F4gUHi4m9QXIa/Zk60f+k52bZJDmwH7wD2JfytyP6lPQNJs="
      );

      const { GoTrains } = data;
      const { GoTrains: GoTrains2 } = data2;
      const filteredTickets = GoTrains.filter(
        (ticket) => ticket.Counting > 0 && ticket.ExitTime === "05:35"
      );
      const filteredTickets2 = GoTrains2.filter(
        (ticket) => ticket.Counting > 0 && ticket.ExitTime === "05:35"
      );
      if (filteredTickets.length > 0 || filteredTickets2.length > 0) {
        const audio = new Audio(beep);
        audio.play();
        if (filteredTickets.length > 0) {
          setStatus("23");
        }
        if (filteredTickets2.length > 0) {
          setStatus("24");
        }
      } else {
        setStatus("nothing");
      }
      setError("");
      setTickets(GoTrains);
    } catch (error) {
      setError("خطا در دریافت اطلاعات از سرور رجا");
    }
  };
  return (
    <>
      <div>
        {tickets.length > 0 ? renderTickets() : ""} {counter}
      </div>
      <div>{error}</div>
      <div>{status === "23" ? "خالی شد" : ""}</div>
      <div>{status === "24" ? "ویژه برادران خالی شد" : ""}</div>
      {!buttonState ? (
        <button
          onClick={() => {
            setButtonState(true);
          }}
        >
          Click to activate sound
        </button>
      ) : (
        ""
      )}
      <div>{buttonState ? "صدا میتونه پخش شه " : ""}</div>
    </>
  );
}

export default App;
